import React, { startTransition } from 'react';
import DashboardService from '~/services/dashboard.service';
import { connect } from 'react-redux';
import DateRangeSelect from '../baseComponents/inputs/date/DateRangeSelect';
import { dateUtils } from '~/utils/dateUtils';
import Log from '~/utils/Log';
import { Spinner } from '../Spinner';
import { withErrorBoundary } from '~/ui/atoms';
import cloneDeep from 'lodash/cloneDeep';
import {
  setDashboard_tab,
  setDashboard_selectedDateRange,
  setDashboard_selectedPredefinedDateRange,
  setDashboard_individualDateRange,
  setDashboard_selectedUnit,
  setDashboard_selectedToSiteRecipient,
  setDashboard_selectedToSiteSupplier,
  setDashboard_selectedCostCenter,
  setDashboard_selectedArticleNumber,
  setDashboard_selectedArticle,
  setDashboard_selectedSupplier,
  setDashboard_selectedRecipient,
  setDashboard_selectedProcessState,
  setDashboard_selectedFromSite,
  setDashboard_selectedArticleInDetailOverview,
  setDashboard_selectedCustomFields,
  setDashboard_selectedToSiteSupplierTradeContact,
  setDashboard_selectedPermittedToSites,
  setDashboard_selectedPermittedCostCenters,
  setDashboard_selectedAcceptState,
  setDashboard_selectedSettledStatus,
} from '~/redux/filtersSlice';
import {
  setArchiveAnalyticsData,
  setArchiveAnalyticsDataLoading,
} from '~/redux/deliveryNotesSlice';
import DashboardCharts from './DashboardCharts';
import { Box, Tab, Tabs } from '@mui/material';
import DashboardReport from './DashboardReport';
import { LOADING_STATE } from '~/constants/LoadingState';
import { TAB } from '~/constants/Tab';
import FeatureService from '~/services/feature.service';
import ClientPortalTooltip from '../salesPackages/clientPortal/ClientPortalTooltip';
import { setPageTitle } from '~/redux/menuSlice';
import ArrayUtils from '~/utils/arrayUtils';
import DashboardFilterUnitChips from './DashboardFilterUnitChips';
import DashboardFilterGroups from './DashboardFilterGroups';
import PackageBasicRestrictionTooltip from '../salesPackages/packageBasicRestriction/packageBasicRestrictionTooltip';
import { promiseHandler } from '~/utils/promiseHandler';
import DeliveriesService from '~/services/deliveries.service';
import FilterGroupFilter from '~/models/filters/FilterGroupFilter';
import FilterContext from '~/models/filters/FilterContext';

const mapStateToProps = (state) => ({
  deliveryNotes: state.deliveryNotes,
  userinfo: state.userinfo,
  // subscribe to companyAccount state so that update of clientPortal feature flag leads to rerender
  companyAccount: state.companyAccount,
  selectedTab: state.filters.dashboard_tab,
  selectedDateRange: state.filters.dashboard_selectedDateRange,
  selectedPredefinedDateRange:
    state.filters.dashboard_selectedPredefinedDateRange,
  individualDateRange: state.filters.dashboard_individualDateRange,
  selectedSites: state.filters.selectedSites,
  selectedCostCenters: state.filters.selectedCostCenters,
  selectedUnit: state.filters.dashboard_selectedUnit,
  selectedToSiteRecipient: state.filters.dashboard_selectedToSiteRecipient,
  selectedToSiteSupplier: state.filters.dashboard_selectedToSiteSupplier,
  selectedCostCenter: state.filters.dashboard_selectedCostCenter,
  selectedArticleNumber: state.filters.dashboard_selectedArticleNumber,
  selectedArticle: state.filters.dashboard_selectedArticle,
  selectedSupplier: state.filters.dashboard_selectedSupplier,
  selectedRecipient: state.filters.dashboard_selectedRecipient,
  selectedProcessState: state.filters.dashboard_selectedProcessState,
  selectedAcceptState: state.filters.dashboard_selectedAcceptState,
  selectedSettledStatus: state.filters.dashboard_selectedSettledStatus,
  selectedFromSite: state.filters.dashboard_selectedFromSite,
  selectedPermittedToSites: state.filters.dashboard_selectedPermittedToSites,
  selectedPermittedCostCenters:
    state.filters.dashboard_selectedPermittedCostCenters,
  selectedCustomFields: state.filters.dashboard_selectedCustomFields,
  selectedToSiteSupplierTradeContact:
    state.filters.dashboard_selectedToSiteSupplierTradeContact,
  selectedFilterGroup: state.filters.dashboard_selectedFilterGroup,
  filterGroups: state.filters.dashboard_filterGroups,
  selectedArticleInDetailOverview:
    state.filters.dashboard_selectedArticleInDetailOverview,
  oldestFilteredDlnDate: state.filters.oldestFilteredDlnDate,
});
const mapDispatchToProps = () => ({
  setDashboard_tab,
  setDashboard_selectedDateRange,
  setDashboard_selectedPredefinedDateRange,
  setDashboard_individualDateRange,
  setDashboard_selectedUnit,
  setDashboard_selectedToSiteRecipient,
  setDashboard_selectedToSiteSupplier,
  setDashboard_selectedCostCenter,
  setDashboard_selectedArticleNumber,
  setDashboard_selectedArticle,
  setDashboard_selectedSupplier,
  setDashboard_selectedRecipient,
  setDashboard_selectedProcessState,
  setDashboard_selectedAcceptState,
  setDashboard_selectedSettledStatus,
  setDashboard_selectedFromSite,
  setDashboard_selectedPermittedToSites,
  setDashboard_selectedPermittedCostCenters,
  setDashboard_selectedArticleInDetailOverview,
  setDashboard_selectedCustomFields,
  setDashboard_selectedToSiteSupplierTradeContact,
  setPageTitle,
  setArchiveAnalyticsData,
  setArchiveAnalyticsDataLoading,
});

class DashboardOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allData: [], // Unfiltered data fetched from backend via redux store.
      allDataVersion: 0,
      preUnitAndDateRangeFilteredData: [], // Data before unit and data range filter has been applied. So row item filters only have been applied.
      preArticleFilteredData: [], // Filtered data without the checkbox selection from the DashboardDetailOverview.
      filteredData: [], // Actual filtered data that is passed to the charts.
      filteredDataVersion: 0,
      selectableUnits: [], // Distinct list of the units from the delivery note articles.
    };

    // To track whether the open HTTP requests are loading data for the latest filter.
    // If the requests are already stale, the response is thrown away.
    this.filterId = 0;
  }

  componentDidMount() {
    // At first load all data of dlns into the dashboard and update the list of selectable units.
    this.loadDashboardData();

    this.props.setPageTitle('Statistiken');
    document.title = 'VESTIGAS - Statistiken';
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.selectedFilterGroup &&
      this.props.selectedFilterGroup !== prevProps.selectedFilterGroup
    ) {
      this.handleChangeFilterGroup();
    }

    if (
      this.isArchiveMode() &&
      this.props.selectedTab === TAB.DASHBOARD.REPORT.INDEX
    ) {
      this.props.setDashboard_tab(TAB.DASHBOARD.CHARTS.INDEX);
    }

    if (
      !this.isArchiveMode() &&
      (this.props.deliveryNotes.filteredDeliveryNotesVersion !==
        prevProps.deliveryNotes.filteredDeliveryNotesVersion ||
        JSON.stringify(this.props.selectedDateRange) !==
          JSON.stringify(prevProps.selectedDateRange) ||
        this.props.oldestFilteredDlnDate !== prevProps.oldestFilteredDlnDate)
    ) {
      // Refresh the currently selected unit, if:
      const refreshSelectedUnit =
        // the first bulk of dlns has been loaded
        (prevProps.deliveryNotes.filteredDeliveryNotes.length === 0 &&
          this.props.deliveryNotes.filteredDeliveryNotes.length > 0) ||
        // the date range has been changed
        JSON.stringify(this.props.selectedCustomFields) !==
          JSON.stringify(prevProps.selectedCustomFields) ||
        // the oldest filtered dln date has been changed
        this.props.oldestFilteredDlnDate !== prevProps.oldestFilteredDlnDate;
      // because in those cases we don't know if the selected unit will be in the new list of dlns.

      this.initDashboardData(refreshSelectedUnit);
    }

    // Only update the archive data when one of the filters has changed because updating the normal data is already
    // done in this.handleFilterChange. Handling the update in this.handleFilterChange improves the performance
    // of the dashboard. This is way the componentDidUpdate function looks different than in DeliveryList.js
    if (
      this.isArchiveMode() &&
      (JSON.stringify(this.props.selectedSites) !==
        JSON.stringify(prevProps.selectedSites) ||
        JSON.stringify(this.props.selectedCostCenters) !==
          JSON.stringify(prevProps.selectedCostCenters) ||
        JSON.stringify(this.props.selectedToSiteRecipient) !==
          JSON.stringify(prevProps.selectedToSiteRecipient) ||
        JSON.stringify(this.props.selectedToSiteSupplier) !==
          JSON.stringify(prevProps.selectedToSiteSupplier) ||
        JSON.stringify(this.props.selectedCostCenter) !==
          JSON.stringify(prevProps.selectedCostCenter) ||
        JSON.stringify(this.props.selectedArticleNumber) !==
          JSON.stringify(prevProps.selectedArticleNumber) ||
        JSON.stringify(this.props.selectedArticle) !==
          JSON.stringify(prevProps.selectedArticle) ||
        JSON.stringify(this.props.selectedSupplier) !==
          JSON.stringify(prevProps.selectedSupplier) ||
        JSON.stringify(this.props.selectedRecipient) !==
          JSON.stringify(prevProps.selectedRecipient) ||
        JSON.stringify(this.props.selectedProcessState) !==
          JSON.stringify(prevProps.selectedProcessState) ||
        JSON.stringify(this.props.selectedAcceptState) !==
          JSON.stringify(prevProps.selectedAcceptState) ||
        JSON.stringify(this.props.selectedSettledStatus) !==
          JSON.stringify(prevProps.selectedSettledStatus) ||
        JSON.stringify(this.props.selectedFromSite) !==
          JSON.stringify(prevProps.selectedFromSite) ||
        JSON.stringify(this.props.selectedPermittedToSites) !==
          JSON.stringify(prevProps.selectedPermittedToSites) ||
        JSON.stringify(this.props.selectedPermittedCostCenters) !==
          JSON.stringify(prevProps.selectedPermittedCostCenters) ||
        JSON.stringify(this.props.selectedToSiteSupplierTradeContact) !==
          JSON.stringify(prevProps.selectedToSiteSupplierTradeContact) ||
        JSON.stringify(this.props.selectedCustomFields) !==
          JSON.stringify(prevProps.selectedCustomFields) ||
        JSON.stringify(this.props.selectedDateRange) !==
          JSON.stringify(prevProps.selectedDateRange) ||
        this.props.selectedUnit !== prevProps.selectedUnit)
    ) {
      // When one of the filters has changed, the filterId is updated so that outdated requests can be detected.
      this.filterId += 1;

      this.loadArchiveDashboardData(this.filterId);
    }
  }

  loadDashboardData() {
    if (!this.isArchiveMode()) {
      this.initDashboardData();
      return;
    }

    this.loadArchiveDashboardData(this.filterId);
  }

  initDashboardData(refreshSelectedUnit) {
    const allData = DashboardService.transformDeliveryNotes(
      this.props.deliveryNotes.filteredDeliveryNotes,
    );

    let selectedProcessState = this.props.selectedProcessState;

    // Reset selected process state if the client portal feature flag is activated so that the dlns aren't filtered by "Geliefert"
    if (
      (FeatureService.clientPortal() ||
        FeatureService.packageBasicRestriction()) &&
      this.props.selectedProcessState
    ) {
      selectedProcessState = [];
      this.props.setDashboard_selectedProcessState([]);
    }

    let selectedAcceptState = this.props.selectedAcceptState;
    let selectedSettledStatus = this.props.selectedSettledStatus;
    let selectedToSiteRecipient = this.props.selectedToSiteRecipient;
    let selectedToSiteSupplier = this.props.selectedToSiteSupplier;
    let selectedCostCenter = this.props.selectedCostCenter;
    let selectedArticleNumber = this.props.selectedArticleNumber;
    let selectedArticle = this.props.selectedArticle;
    let selectedSupplier = this.props.selectedSupplier;
    let selectedRecipient = this.props.selectedRecipient;
    let selectedFromSite = this.props.selectedFromSite;
    let selectedPermittedToSites = this.props.selectedPermittedToSites;
    let selectedPermittedCostCenters = this.props.selectedPermittedCostCenters;
    let selectedCustomFields = this.props.selectedCustomFields;
    let selectedToSiteSupplierTradeContact =
      this.props.selectedToSiteSupplierTradeContact;

    // If a filter group has been selected, update the filter values and filter the data accordingly.
    const filterGroup = this.props.filterGroups.find(
      (filterGroup) => filterGroup.id === this.props.selectedFilterGroup,
    );

    if (filterGroup) {
      // When adding new filters, it is possible that there already exists a filter group without this filter.
      // This would result in an undefined variable which would lead to problems later in the code.
      selectedToSiteRecipient =
        filterGroup.filters.selectedToSiteRecipient ?? [];
      selectedToSiteSupplier = filterGroup.filters.selectedToSiteSupplier ?? [];
      selectedCostCenter = filterGroup.filters.selectedCostCenter ?? [];
      selectedArticleNumber = filterGroup.filters.selectedArticleNumber ?? [];
      selectedArticle = filterGroup.filters.selectedArticle ?? [];
      selectedSupplier = filterGroup.filters.selectedSupplier ?? [];
      selectedProcessState = filterGroup.filters.selectedProcessState ?? [];
      selectedAcceptState = filterGroup.filters.selectedAcceptState ?? [];
      selectedSettledStatus = filterGroup.filters.selectedSettledStatus ?? [];
      selectedRecipient = filterGroup.filters.selectedRecipient ?? [];
      selectedFromSite = filterGroup.filters.selectedFromSite ?? [];
      selectedPermittedToSites =
        filterGroup.filters.selectedPermittedToSites ?? [];
      selectedPermittedCostCenters =
        filterGroup.filters.selectedPermittedCostCenters ?? [];
      selectedCustomFields = filterGroup.filters.selectedCustomFields ?? [];
      selectedToSiteSupplierTradeContact =
        filterGroup.filters.selectedToSiteSupplierTradeContact ?? [];

      this.props.setDashboard_selectedToSiteRecipient(selectedToSiteRecipient);
      this.props.setDashboard_selectedToSiteSupplier(selectedToSiteSupplier);
      this.props.setDashboard_selectedCostCenter(selectedCostCenter);
      this.props.setDashboard_selectedArticleNumber(selectedArticleNumber);
      this.props.setDashboard_selectedArticle(selectedArticle);
      this.props.setDashboard_selectedSupplier(selectedSupplier);
      this.props.setDashboard_selectedRecipient(selectedRecipient);
      this.props.setDashboard_selectedProcessState(selectedProcessState);
      this.props.setDashboard_selectedAcceptState(selectedAcceptState);
      this.props.setDashboard_selectedSettledStatus(selectedSettledStatus);
      this.props.setDashboard_selectedFromSite(selectedFromSite);
      this.props.setDashboard_selectedPermittedToSites(
        selectedPermittedToSites,
      );
      this.props.setDashboard_selectedPermittedCostCenters(
        selectedPermittedCostCenters,
      );
      this.props.setDashboard_selectedCustomFields(selectedCustomFields);
      this.props.setDashboard_selectedToSiteSupplierTradeContact(
        selectedToSiteSupplierTradeContact,
      );
    }

    // The data in preUnitAndDateRangeFilteredData is the basis for creating the selectableUnits list.
    const preUnitAndDateRangeFilteredData = DashboardService.filterData(
      allData,
      selectedToSiteRecipient,
      selectedToSiteSupplier,
      selectedCostCenter,
      selectedArticleNumber,
      selectedArticle,
      selectedSupplier,
      selectedRecipient,
      selectedProcessState,
      selectedAcceptState,
      selectedSettledStatus,
      selectedFromSite,
      selectedPermittedToSites,
      selectedPermittedCostCenters,
      selectedCustomFields,
      selectedToSiteSupplierTradeContact,
      null,
      null,
      null,
    );

    // Create a list of units ordered by the frequency of occurrence of the units.
    const selectableUnits = ArrayUtils.sortByKey(
      DashboardService.getUnitFrequencies(preUnitAndDateRangeFilteredData),
      'frequency',
      true,
    );
    let selectedUnit = this.props.selectedUnit;

    const validUnitSelected = selectableUnits
      .map((selectableUnit) => selectableUnit.unit)
      .includes(selectedUnit);

    if (refreshSelectedUnit || !validUnitSelected) {
      selectedUnit = selectableUnits[0]?.unit ?? null;
      this.props.setDashboard_selectedUnit(selectedUnit);
    }

    // Directly apply the unit and date range filter so that no performance is "lost" by waiting for a state update of preUnitAndDateRangeFilteredData.
    const preArticleFilteredData = DashboardService.filterData(
      preUnitAndDateRangeFilteredData,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      null,
      selectedUnit,
      dateUtils.extractTimeframe(this.props.selectedDateRange),
      null,
    );

    startTransition(() => {
      this.setState({
        allData,
        allDataVersion: this.state.allDataVersion + 1,
        preUnitAndDateRangeFilteredData,
        preArticleFilteredData,
        filteredData: preArticleFilteredData,
        filteredDataVersion: this.state.filteredDataVersion + 1,
        selectableUnits,
      });
    });
  }

  async loadArchiveDashboardData(filterId) {
    if (!this.props.selectedUnit) {
      return;
    }

    // If the cached data is up-to-date, take the cached data.
    if (
      JSON.stringify(this.getAppliedFilters()) ===
      JSON.stringify(
        this.props.deliveryNotes.archiveAnalyticsDataAppliedFilters,
      )
    ) {
      return;
    }

    this.props.setArchiveAnalyticsDataLoading(LOADING_STATE.LOADING);

    const [response, error] = await promiseHandler(
      DeliveriesService.getAnalyticsData(
        this.props.selectedProcessState,
        this.props.selectedAcceptState,
        this.props.selectedSettledStatus,
        this.props.selectedToSiteRecipient,
        this.props.selectedToSiteSupplier,
        this.props.selectedArticle,
        this.props.selectedSupplier,
        this.props.selectedRecipient,
        this.props.selectedPermittedToSites,
        this.props.selectedPermittedCostCenters,
        this.props.selectedSites,
        this.props.selectedCostCenters,
        this.props.selectedUnit,
        this.props.selectedDateRange,
        null,
      ),
    );

    // If the filterId has been updated in the meantime, the request is outdated and the response is thrown away.
    if (this.filterId !== filterId) {
      return;
    }

    if (error) {
      Log.error('Error while loading archive analytics data', error);
      this.props.setArchiveAnalyticsDataLoading(LOADING_STATE.FAILED);
      return;
    }

    this.props.setArchiveAnalyticsData({
      archiveAnalyticsData: response,
      appliedFilters: this.getAppliedFilters(),
    });
  }

  isArchiveMode() {
    return DeliveriesService.isArchiveMode(this.props.selectedDateRange);
  }

  getAppliedFilters() {
    return {
      selectedAcceptState: this.props.selectedAcceptState,
      selectedArticle: this.props.selectedArticle,
      selectedArticleNumber: this.props.selectedArticleNumber,
      selectedCostCenter: this.props.selectedCostCenter,
      selectedCostCenters: this.props.selectedCostCenters,
      selectedCustomFields: this.props.selectedCustomFields,
      selectedDateRange: this.props.selectedDateRange,
      selectedFromSite: this.props.selectedFromSite,
      selectedPermittedCostCenters: this.props.selectedPermittedCostCenters,
      selectedPermittedToSites: this.props.selectedPermittedToSites,
      selectedProcessState: this.props.selectedProcessState,
      selectedRecipient: this.props.selectedRecipient,
      selectedSettledStatus: this.props.selectedSettledStatus,
      selectedSites: this.props.selectedSites,
      selectedSupplier: this.props.selectedSupplier,
      selectedToSiteRecipient: this.props.selectedToSiteRecipient,
      selectedToSiteSupplier: this.props.selectedToSiteSupplier,
      selectedToSiteSupplierTradeContact:
        this.props.selectedToSiteSupplierTradeContact,
      selectedUnit: this.props.selectedUnit,
    };
  }

  handleChangeFilterGroup() {
    const filterGroup = this.props.filterGroups.find(
      (filterGroup) => filterGroup.id === this.props.selectedFilterGroup,
    );

    this.props.setDashboard_selectedToSiteRecipient(
      filterGroup.filters.selectedToSiteRecipient ?? [],
    );
    this.props.setDashboard_selectedToSiteSupplier(
      filterGroup.filters.selectedToSiteSupplier ?? [],
    );
    this.props.setDashboard_selectedCostCenter(
      filterGroup.filters.selectedCostCenter ?? [],
    );
    this.props.setDashboard_selectedArticleNumber(
      filterGroup.filters.selectedArticleNumber ?? [],
    );
    this.props.setDashboard_selectedArticle(
      filterGroup.filters.selectedArticle ?? [],
    );
    this.props.setDashboard_selectedSupplier(
      filterGroup.filters.selectedSupplier ?? [],
    );
    this.props.setDashboard_selectedRecipient(
      filterGroup.filters.selectedRecipient ?? [],
    );
    this.props.setDashboard_selectedProcessState(
      filterGroup.filters.selectedProcessState ?? [],
    );
    this.props.setDashboard_selectedAcceptState(
      filterGroup.filters.selectedAcceptState ?? [],
    );
    this.props.setDashboard_selectedSettledStatus(
      filterGroup.filters.selectedSettledStatus ?? [],
    );
    this.props.setDashboard_selectedFromSite(
      filterGroup.filters.selectedFromSite ?? [],
    );
    this.props.setDashboard_selectedPermittedToSites(
      filterGroup.filters.selectedPermittedToSites ?? [],
    );
    this.props.setDashboard_selectedPermittedCostCenters(
      filterGroup.filters.selectedPermittedCostCenters ?? [],
    );
    this.props.setDashboard_selectedCustomFields(
      filterGroup.filters.selectedCustomFields ?? [],
    );
    this.props.setDashboard_selectedToSiteSupplierTradeContact(
      filterGroup.filters.selectedToSiteSupplierTradeContact ?? [],
    );

    if (this.isArchiveMode()) {
      return;
    } // Handle in componentDidUpdate

    const preUnitAndDateRangeFilteredData = DashboardService.filterData(
      this.state.allData,
      filterGroup.filters.selectedToSiteRecipient,
      filterGroup.filters.selectedToSiteSupplier,
      filterGroup.filters.selectedCostCenter,
      filterGroup.filters.selectedArticleNumber,
      filterGroup.filters.selectedArticle,
      filterGroup.filters.selectedSupplier,
      filterGroup.filters.selectedRecipient,
      filterGroup.filters.selectedProcessState,
      filterGroup.filters.selectedAcceptState,
      filterGroup.filters.selectedSettledStatus,
      filterGroup.filters.selectedFromSite,
      filterGroup.filters.selectedPermittedToSites,
      filterGroup.filters.selectedPermittedCostCenters,
      filterGroup.filters.selectedCustomFields,
      filterGroup.filters.selectedToSiteSupplierTradeContact,
      null,
      null,
      null,
    );

    // Because filter has been updated and thus the data in preUnitAndDateRangeFilteredData has changed, refresh the selectable units.
    const selectableUnits = ArrayUtils.sortByKey(
      DashboardService.getUnitFrequencies(preUnitAndDateRangeFilteredData),
      'frequency',
      true,
    );
    let selectedUnit = this.props.selectedUnit;

    const validUnitSelected = selectableUnits
      .map((selectableUnit) => selectableUnit.unit)
      .includes(selectedUnit);

    if (!validUnitSelected) {
      selectedUnit = selectableUnits[0]?.unit ?? null;
      this.props.setDashboard_selectedUnit(selectedUnit);
    }

    // Directly apply the unit and date range filter so that no performance is "lost" by waiting for a state update of preUnitAndDateRangeFilteredData.
    const preArticleFilteredData = DashboardService.filterData(
      preUnitAndDateRangeFilteredData,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      null,
      selectedUnit,
      dateUtils.extractTimeframe(this.props.selectedDateRange),
      null,
    );

    startTransition(() => {
      this.setState({
        filteredData: preArticleFilteredData,
        filteredDataVersion: this.state.filteredDataVersion + 1,
        preArticleFilteredData,
        preUnitAndDateRangeFilteredData,
        selectableUnits,
      });
    });
  }

  // Callback from FilterGroups so that Dashboard state selectedToSiteSupplier, etc. are up-to-date, data is filtered and chart is updated accordingly
  handleFilterChange = (type, customField, filterValue) => {
    switch (type) {
      case FilterGroupFilter.FILTER.SELECTED_TO_SITE_RECIPIENT: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedToSiteRecipient, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter toSiteRecipient',
          Log.FEATURE.DASHBOARD,
        );

        this.props.setDashboard_selectedToSiteRecipient(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedToSiteSupplier, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter toSiteSupplier',
          Log.FEATURE.DASHBOARD,
        );

        this.props.setDashboard_selectedToSiteSupplier(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_COST_CENTER: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedCostCenter, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent('Filter cost center', Log.FEATURE.DASHBOARD);

        this.props.setDashboard_selectedCostCenter(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_ARTICLE_NUMBER: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedArticleNumber, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter article number',
          Log.FEATURE.DASHBOARD,
        );

        this.props.setDashboard_selectedArticleNumber(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_ARTICLE: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedArticle, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent('Filter article', Log.FEATURE.DASHBOARD);

        this.props.setDashboard_selectedArticle(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_SUPPLIER: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedSupplier, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent('Filter supplier', Log.FEATURE.DASHBOARD);

        this.props.setDashboard_selectedSupplier(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_RECIPIENT: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedRecipient, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent('Filter recipient', Log.FEATURE.DASHBOARD);

        this.props.setDashboard_selectedRecipient(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER_TRADE_CONTACT: {
        Log.info(
          'Change filter value of ' + type,
          {
            from: this.props.selectedToSiteSupplierTradeContact,
            to: filterValue,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter toSiteSupplierTradeContact',
          Log.FEATURE.DASHBOARD,
        );

        this.props.setDashboard_selectedToSiteSupplierTradeContact(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_PROCESS_STATE: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedProcessState, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter process state',
          Log.FEATURE.DASHBOARD,
        );

        this.props.setDashboard_selectedProcessState(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_ACCEPT_STATE: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedAcceptState, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent('Filter accept state', Log.FEATURE.DASHBOARD);

        this.props.setDashboard_selectedAcceptState(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_SETTLED_STATUS: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedSettledStatus, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter settled status',
          Log.FEATURE.DASHBOARD,
        );

        this.props.setDashboard_selectedSettledStatus(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_FROM_SITE: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedFromSite, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent('Filter fromSite', Log.FEATURE.DASHBOARD);

        this.props.setDashboard_selectedFromSite(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_PERMITTED_TO_SITES: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedPermittedToSites, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter permittedToSites',
          Log.FEATURE.DASHBOARD,
        );

        this.props.setDashboard_selectedPermittedToSites(filterValue);

        break;
      }

      case FilterGroupFilter.FILTER.SELECTED_PERMITTED_COST_CENTERS: {
        Log.info(
          'Change filter value of ' + type,
          { from: this.props.selectedPermittedCostCenters, to: filterValue },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Filter permittedCostCenters',
          Log.FEATURE.DASHBOARD,
        );

        this.props.setDashboard_selectedPermittedCostCenters(filterValue);

        break;
      }

      default: {
        break;
      }
    }

    const newSelectedCustomFields = cloneDeep(this.props.selectedCustomFields);

    if (customField) {
      const selectedCustomFieldIndex =
        this.props.selectedCustomFields.findIndex(
          (selectedCustomField) => selectedCustomField.key === customField.key,
        );

      Log.info(
        'Change filter value of ' + customField.key,
        {
          from: this.props.selectedCustomFields[selectedCustomFieldIndex]
            ?.filterValue,
          to: filterValue,
        },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Filter custom field', Log.FEATURE.DASHBOARD);

      if (selectedCustomFieldIndex === -1) {
        // If the custom field has not filter applied yet, push it to the selectedCustomFields filter.
        newSelectedCustomFields.push({
          key: customField.key,
          filterValue,
        });
      } else if (filterValue.length > 0) {
        // If a filter value has been added, overwrite the custom field filter
        newSelectedCustomFields[selectedCustomFieldIndex].filterValue =
          filterValue;
      } else {
        // If all filter values have been removed, remove the whole custom field entry from the selectedCustomFields variable
        // because we don't want empty custom field entries in selectedCustomFields.
        newSelectedCustomFields.splice(selectedCustomFieldIndex, 1);
      }

      this.props.setDashboard_selectedCustomFields(newSelectedCustomFields);
    }

    if (this.isArchiveMode()) {
      return;
    } // Handle in componentDidUpdate

    const preUnitAndDateRangeFilteredData = DashboardService.filterData(
      this.state.allData,
      type === FilterGroupFilter.FILTER.SELECTED_TO_SITE_RECIPIENT
        ? filterValue
        : this.props.selectedToSiteRecipient,
      type === FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER
        ? filterValue
        : this.props.selectedToSiteSupplier,
      type === FilterGroupFilter.FILTER.SELECTED_COST_CENTER
        ? filterValue
        : this.props.selectedCostCenter,
      type === FilterGroupFilter.FILTER.SELECTED_ARTICLE_NUMBER
        ? filterValue
        : this.props.selectedArticleNumber,
      type === FilterGroupFilter.FILTER.SELECTED_ARTICLE
        ? filterValue
        : this.props.selectedArticle,
      type === FilterGroupFilter.FILTER.SELECTED_SUPPLIER
        ? filterValue
        : this.props.selectedSupplier,
      type === FilterGroupFilter.FILTER.SELECTED_RECIPIENT
        ? filterValue
        : this.props.selectedRecipient,
      type === FilterGroupFilter.FILTER.SELECTED_PROCESS_STATE
        ? filterValue
        : this.props.selectedProcessState,
      type === FilterGroupFilter.FILTER.SELECTED_ACCEPT_STATE
        ? filterValue
        : this.props.selectedAcceptState,
      type === FilterGroupFilter.FILTER.SELECTED_SETTLED_STATUS
        ? filterValue
        : this.props.selectedSettledStatus,
      type === FilterGroupFilter.FILTER.SELECTED_FROM_SITE
        ? filterValue
        : this.props.selectedFromSite,
      type === FilterGroupFilter.FILTER.SELECTED_PERMITTED_TO_SITES
        ? filterValue
        : this.props.selectedPermittedToSites,
      type === FilterGroupFilter.FILTER.SELECTED_PERMITTED_COST_CENTERS
        ? filterValue
        : this.props.selectedPermittedCostCenters,
      newSelectedCustomFields,
      type === FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER_TRADE_CONTACT
        ? filterValue
        : this.props.selectedToSiteSupplierTradeContact,
      null,
      null,
      null,
    );

    // Because filter has been updated and thus the data in preUnitAndDateRangeFilteredData has changed, refresh the selectable units.
    const selectableUnits = ArrayUtils.sortByKey(
      DashboardService.getUnitFrequencies(preUnitAndDateRangeFilteredData),
      'frequency',
      true,
    );
    let selectedUnit = this.props.selectedUnit;

    const validUnitSelected = selectableUnits
      .map((selectableUnit) => selectableUnit.unit)
      .includes(selectedUnit);

    if (!validUnitSelected) {
      selectedUnit = selectableUnits[0]?.unit ?? null;
      this.props.setDashboard_selectedUnit(selectedUnit);
    }

    // Directly apply the unit and date range filter so that no performance is "lost" by waiting for a state update of preUnitAndDateRangeFilteredData.
    const preArticleFilteredData = DashboardService.filterData(
      preUnitAndDateRangeFilteredData,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      null,
      selectedUnit,
      dateUtils.extractTimeframe(this.props.selectedDateRange),
      null,
    );

    startTransition(() => {
      this.setState({
        preUnitAndDateRangeFilteredData,
        preArticleFilteredData,
        filteredData: preArticleFilteredData,
        filteredDataVersion: this.state.filteredDataVersion + 1,
        selectableUnits,
      });
    });
  };
  // Callback from DetailOverview so that Dashboard selectedArticleInDetailOverview is up-to-date
  handleSelectedArticleInDetailOverviewChange = (
    selectedArticleInDetailOverview,
  ) => {
    this.props.setDashboard_selectedArticleInDetailOverview(
      selectedArticleInDetailOverview,
    );

    if (this.isArchiveMode()) {
      return;
    } // Handle in componentDidUpdate

    const filteredData = DashboardService.filterData(
      this.state.allData,
      this.props.selectedToSiteRecipient,
      this.props.selectedToSiteSupplier,
      this.props.selectedCostCenter,
      this.props.selectedArticleNumber,
      this.props.selectedArticle,
      this.props.selectedSupplier,
      this.props.selectedRecipient,
      this.props.selectedProcessState,
      this.props.selectedAcceptState,
      this.props.selectedSettledStatus,
      this.props.selectedFromSite,
      this.props.selectedPermittedToSites,
      this.props.selectedPermittedCostCenters,
      this.props.selectedCustomFields,
      this.props.selectedToSiteSupplierTradeContact,
      this.props.selectedUnit,
      dateUtils.extractTimeframe(this.props.selectedDateRange),
      selectedArticleInDetailOverview,
    );

    startTransition(() => {
      this.setState({
        filteredData,
        filteredDataVersion: this.state.filteredDataVersion + 1,
      });
    });
  };
  handleDateRangeChange = (value) => {
    const numberOfDays = dateUtils.getNumberOfDays(value[0], value[1]);
    if (numberOfDays >= 0 && numberOfDays <= 3650) {
      Log.info(
        'Change filter value of selected date range',
        { from: this.props.selectedDateRange, to: value },
        Log.BREADCRUMB.FILTER_CHANGE.KEY,
      );
      Log.productAnalyticsEvent('Filter date range', Log.FEATURE.DASHBOARD);

      this.props.setDashboard_selectedDateRange(value);
      this.props.setDashboard_individualDateRange(true);

      // Handle in componentDidUpdate
      if (this.isArchiveMode()) {
        // Per default, the first selectable unit is selected.
        // Anyway, you should find a better solution for this in the future.
        this.props.setDashboard_selectedUnit(
          DashboardService.getMajorSelectableUnitsForArchiveMode()[0].id,
        );
        return;
      }

      const preArticleFilteredData = DashboardService.filterData(
        this.state.allData,
        this.props.selectedToSiteRecipient,
        this.props.selectedToSiteSupplier,
        this.props.selectedCostCenter,
        this.props.selectedArticleNumber,
        this.props.selectedArticle,
        this.props.selectedSupplier,
        this.props.selectedRecipient,
        this.props.selectedProcessState,
        this.props.selectedAcceptState,
        this.props.selectedSettledStatus,
        this.props.selectedFromSite,
        this.props.selectedPermittedToSites,
        this.props.selectedPermittedCostCenters,
        this.props.selectedCustomFields,
        this.props.selectedToSiteSupplierTradeContact,
        this.props.selectedUnit,
        dateUtils.extractTimeframe(value),
        null,
      );

      startTransition(() => {
        this.setState({
          preArticleFilteredData,
          filteredData: preArticleFilteredData,
          filteredDataVersion: this.state.filteredDataVersion + 1,
        });
      });
    }
  };
  handlePredefinedDateRangeChange = (value) => {
    Log.info(
      'Change filter value of selected predefined date range',
      { from: this.props.selectedPredefinedDateRange, to: value },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Filter predefined date range: ' + value,
      Log.FEATURE.DASHBOARD,
    );

    const timeframe = dateUtils.getTimeframeFromDateRange(value);

    this.props.setDashboard_selectedPredefinedDateRange(value);
    this.props.setDashboard_selectedDateRange(timeframe);
    this.props.setDashboard_individualDateRange(false);

    if (this.isArchiveMode()) {
      return;
    } // Handle in componentDidUpdate

    const preArticleFilteredData = DashboardService.filterData(
      this.state.allData,
      this.props.selectedToSiteRecipient,
      this.props.selectedToSiteSupplier,
      this.props.selectedCostCenter,
      this.props.selectedArticleNumber,
      this.props.selectedArticle,
      this.props.selectedSupplier,
      this.props.selectedRecipient,
      this.props.selectedProcessState,
      this.props.selectedAcceptState,
      this.props.selectedSettledStatus,
      this.props.selectedFromSite,
      this.props.selectedPermittedToSites,
      this.props.selectedPermittedCostCenters,
      this.props.selectedCustomFields,
      this.props.selectedToSiteSupplierTradeContact,
      this.props.selectedUnit,
      dateUtils.extractTimeframe(timeframe),
      null,
    );

    startTransition(() => {
      this.setState({
        preArticleFilteredData,
        filteredData: preArticleFilteredData,
        filteredDataVersion: this.state.filteredDataVersion + 1,
      });
    });
  };
  handleUnitChange = (unit) => {
    Log.info(
      'Change filter value of unit',
      { from: this.props.selectedUnit, to: unit },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Filter unit', Log.FEATURE.DASHBOARD);

    this.props.setDashboard_selectedUnit(unit);

    if (this.isArchiveMode()) {
      return;
    } // Handle in componentDidUpdate

    const preArticleFilteredData = DashboardService.filterData(
      this.state.preUnitAndDateRangeFilteredData,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      null,
      unit,
      dateUtils.extractTimeframe(this.props.selectedDateRange),
      null,
    );

    startTransition(() => {
      this.setState({
        preArticleFilteredData,
        filteredData: preArticleFilteredData,
        filteredDataVersion: this.state.filteredDataVersion + 1,
      });
    });
  };
  handleChangeTab = (event, value) => {
    if (value === TAB.DASHBOARD.CHARTS.INDEX) {
      Log.productAnalyticsEvent('Open charts tab', Log.FEATURE.DASHBOARD);
    }

    if (value === TAB.DASHBOARD.REPORT.INDEX) {
      Log.productAnalyticsEvent('Open reports tab', Log.FEATURE.DASHBOARD);
    }

    this.props.setDashboard_tab(value);
  };
  resetFilters = () => {
    this.props.setDashboard_selectedToSiteRecipient([]);
    this.props.setDashboard_selectedToSiteSupplier([]);
    this.props.setDashboard_selectedCostCenter([]);
    this.props.setDashboard_selectedArticleNumber([]);
    this.props.setDashboard_selectedArticle([]);
    this.props.setDashboard_selectedSupplier([]);
    this.props.setDashboard_selectedRecipient([]);
    this.props.setDashboard_selectedProcessState([]);
    this.props.setDashboard_selectedAcceptState([]);
    this.props.setDashboard_selectedSettledStatus([]);
    this.props.setDashboard_selectedFromSite([]);
    this.props.setDashboard_selectedPermittedToSites([]);
    this.props.setDashboard_selectedPermittedCostCenters([]);
    this.props.setDashboard_selectedCustomFields([]);
    this.props.setDashboard_selectedToSiteSupplierTradeContact([]);

    if (this.isArchiveMode()) {
      return;
    } // Handle in componentDidUpdate

    // Reset filters and recalculate data.
    const preUnitAndDateRangeFilteredData = DashboardService.filterData(
      this.state.allData,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      null,
      null,
      null,
      null,
    );

    // Because filter has been updateed and thus the data in preUnitAndDateRangeFilteredData has changed, refresh the selectable units.
    const selectableUnits = ArrayUtils.sortByKey(
      DashboardService.getUnitFrequencies(preUnitAndDateRangeFilteredData),
      'frequency',
      true,
    );
    let selectedUnit = this.props.selectedUnit;

    const validUnitSelected = selectableUnits
      .map((selectableUnit) => selectableUnit.unit)
      .includes(selectedUnit);

    if (!validUnitSelected) {
      selectedUnit = selectableUnits[0]?.unit ?? null;
      this.props.setDashboard_selectedUnit(selectedUnit);
    }

    // Directly apply the unit and date range filter so that no performance is "lost" by waiting for a state update of preUnitAndDateRangeFilteredData.
    const preArticleFilteredData = DashboardService.filterData(
      preUnitAndDateRangeFilteredData,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      null,
      selectedUnit,
      dateUtils.extractTimeframe(this.props.selectedDateRange),
      null,
    );

    startTransition(() => {
      this.setState({
        preUnitAndDateRangeFilteredData,
        preArticleFilteredData,
        filteredData: preArticleFilteredData,
        filteredDataVersion: this.state.filteredDataVersion + 1,
        selectableUnits,
      });
    });
  };

  isDataPresent() {
    if (!this.isArchiveMode() && this.state.preArticleFilteredData.length > 0) {
      return true;
    }

    if (
      this.isArchiveMode() &&
      this.props.deliveryNotes.archiveAnalyticsData.length > 0
    ) {
      return true;
    }

    return false;
  }

  getComponentWhenNoDataPresent() {
    if (this.isDataPresent()) {
      return null;
    }

    const message = 'Keine Lieferungen für den angegeben Filter vorhanden.';

    if (this.isArchiveMode()) {
      if (
        this.props.deliveryNotes.archiveAnalyticsDataLoading ===
        LOADING_STATE.FAILED
      ) {
        return 'Lieferungen konnten nicht geladen werden.';
      }
    } else {
      if (
        this.props.deliveryNotes.deliveryNotesLoading === LOADING_STATE.FAILED
      ) {
        return 'Lieferungen konnten nicht geladen werden.';
      }

      if (this.props.deliveryNotes.filteredDeliveryNotes.length === 0) {
        return 'Keine Lieferungen vorhanden.';
      }
    }

    return <div className="flex-c-c h-full">{message}</div>;
  }

  getLoading() {
    if (!this.isArchiveMode()) {
      return this.props.deliveryNotes.deliveryNotesLoading;
    }

    return this.props.deliveryNotes.archiveAnalyticsDataLoading;
  }

  getLoadingComponent() {
    let body = null;

    if (this.getLoading() === LOADING_STATE.LOADING) {
      body = <Spinner />;
    }

    if (this.getLoading() === LOADING_STATE.FAILED) {
      body = 'Lieferungen konnten nicht geladen werden.';
    }

    if (!body) {
      return null;
    }

    return (
      <div className="flexdir-column flex-c-c h-400px flex w-full">{body}</div>
    );
  }

  displayLoadingComponent() {
    return (
      this.getLoading() === LOADING_STATE.LOADING ||
      this.getLoading() === LOADING_STATE.FAILED
    );
  }

  render() {
    // data from backend not yet loaded (if we get empty data, we still show dashboard, but with text indicating no data is present)
    if (
      this.props.deliveryNotes.deliveryNotesLoading === LOADING_STATE.LOADING
    ) {
      return <Spinner title="Dashboard wird geladen..." />;
    }

    return (
      <div className="main-padding flexdir-column flex h-full">
        <div className="p-20px pt-30px pb-30px rounded-5px box-shadow-blue bg-white">
          <div className="text-16px bold">Filter auswählen</div>
          <div className="h-20px" />
          <DashboardFilterGroups
            onChangeValue={(id, customField, filterValue) =>
              this.handleFilterChange(id, customField, filterValue)
            }
            resetFilters={this.resetFilters}
            data={this.state.allData}
            dataVersion={this.state.allDataVersion}
          />
          <div className="h-20px" />
          <div className="border-top pt-20px">
            <div className="bold mb-10px">Einheit</div>
            <DashboardFilterUnitChips
              selectedUnit={this.props.selectedUnit}
              selectableUnits={
                this.isArchiveMode()
                  ? DashboardService.getMajorSelectableUnitsForArchiveMode()
                  : this.state.selectableUnits
              }
              onUnitChange={this.handleUnitChange}
              isArchiveMode={this.isArchiveMode()}
            />
          </div>
          <div className="h-20px" />
          <div className="border-top pt-20px">
            <div className="bold mb-10px">Zeitraum</div>
            <PackageBasicRestrictionTooltip>
              <ClientPortalTooltip>
                <DateRangeSelect
                  predefinedDateRange={this.props.selectedPredefinedDateRange}
                  individualDateRange={this.props.individualDateRange}
                  onPredefinedDateRangeChange={
                    this.handlePredefinedDateRangeChange
                  }
                  dateRange={this.props.selectedDateRange}
                  onDateRangeChange={this.handleDateRangeChange}
                  disabled={
                    FeatureService.clientPortal() ||
                    FeatureService.packageBasicRestriction()
                  }
                  archiveMode
                  displayArchiveModeIcon
                  page={FilterContext.PAGE.DASHBOARD}
                />
              </ClientPortalTooltip>
            </PackageBasicRestrictionTooltip>
          </div>
        </div>
        <div className="mt-20px p-20px pt-8px pb-30px rounded-5px box-shadow-blue flexdir-column flex flex-1 bg-white">
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              marginTop: '10px',
              marginBottom: '30px',
            }}
          >
            <Tabs
              value={this.props.selectedTab}
              onChange={this.handleChangeTab}
            >
              <Tab label={TAB.DASHBOARD.CHARTS.NAME} />
              <Tab
                label={TAB.DASHBOARD.REPORT.NAME}
                disabled={
                  FeatureService.clientPortal() ||
                  FeatureService.packageBasicRestriction() ||
                  this.isArchiveMode()
                }
              />
            </Tabs>
          </Box>
          {this.displayLoadingComponent() && this.getLoadingComponent()}
          {!this.displayLoadingComponent() &&
            this.getComponentWhenNoDataPresent()}
          {!this.displayLoadingComponent() &&
            this.isDataPresent() &&
            this.props.selectedTab === TAB.DASHBOARD.CHARTS.INDEX && (
              <DashboardCharts
                preArticleFilteredData={this.state.preArticleFilteredData}
                filteredData={this.state.filteredData}
                filteredDataVersion={this.state.filteredDataVersion}
                onSelectedArticleInDetailOverviewChange={
                  this.handleSelectedArticleInDetailOverviewChange
                }
                isArchiveMode={this.isArchiveMode()}
              />
            )}
          {!this.displayLoadingComponent() &&
            this.isDataPresent() &&
            this.props.selectedTab === TAB.DASHBOARD.REPORT.INDEX && (
              <DashboardReport
                data={this.state.preArticleFilteredData}
                isArchiveMode={this.isArchiveMode()}
                selectedUnit={this.props.selectedUnit}
              />
            )}
        </div>
        <div
          className="min-h-2rem"
          /* This is a hacky workaround to get the padding bottom of 2rem. It is applied as child container to all divs with main-padding */
          /* A better solution would be to make the parent container min-h-fit-content so that the padding of main-padding is applied. */
          /* However, min-h-fit-content seems to not work with h-fill or generally with flexbox and flex-1. */
        />
      </div>
    );
  }
}

export default withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps())(DashboardOverview),
  'Statistiken konnten nicht geladen werden.',
);
