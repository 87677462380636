import React, { useState, useEffect } from 'react';
import { Switch, useLocation, useHistory } from 'react-router-dom';

import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Info as InfoIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  PersonSearch as PersonSearchIcon,
  PersonSearchOutlined as PersonSearchOutlinedIcon,
} from '@mui/icons-material';
import {
  Button,
  Collapse,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
  Drawer as MuiDrawer,
  Hidden,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import UserService from '~/services/user.service';
import { ROUTE } from '~/constants/Route';
import Home from '../home/Home';
import DashboardOverview from '../dashboard/DashboardOverview';
import { DeliveryOverview } from '../deliveries/deliveryOverview';
import DeliveryNote from '../deliveries/deliveryNote/DeliveryNote';
import DeliveryNoteForm from '../deliveries/deliveryNoteForm/DeliveryNoteForm';
import ServiceNoteForm from '../deliveries/serviceNoteForm/ServiceNoteForm';
import ConcreteDiary from '../concreteDiary/ConcreteDiary';
import InvoiceOverview from '../invoices/invoiceOverview/InvoiceOverview';
import Invoice from '../invoices/Invoice';
import Settings from '../settings/Settings';
import { UnauthorizedPage } from './UnauthorizedPage';
import VestigasLogo from '~/assets/Logo_Standard_white.png';
import RouteUtils from '~/utils/routeUtils';

import { SentryRoute } from '~/services/thirdParty.service';
import LocalStorageService from '~/services/localStorage.service';
import BrowserUtils from '~/utils/browserUtils';
import Log from '~/utils/Log';
import { LOADING_STATE } from '~/constants/LoadingState';
import ToastService from '~/services/toast.service';
import { useSelector, useDispatch } from 'react-redux';
import { setDelivery_tab, setDashboard_tab } from '~/redux/filtersSlice';
import FunctionUtils from '~/utils/functionUtils';
import { TAB } from '~/constants/Tab';

import {
  CreateDeliveryIcon,
  CreateDeliveryOutlinedIcon,
  DeliveryIcon,
  DeliveryOutlinedIcon,
  EditIcon,
  EditOutlinedIcon,
  FolderIcon,
  FolderOutlinedIcon,
  HomeIcon,
  HomeOutlinedIcon,
  IncomingInvoiceIcon,
  IncomingInvoiceOutlinedIcon,
  InvoiceIcon,
  InvoiceOutlinedIcon,
  OutgoingInvoiceIcon,
  OutgoingInvoiceOutlinedIcon,
  PieChartIcon,
  PieChartOutlinedIcon,
  ReportIcon,
  ReportOutlinedIcon,
  SettingsIcon,
  SettingsOutlinedIcon,
  SupplierIcon,
} from '~/assets/icons';

import { LightTooltip } from '~/utils/componentUtils';
import FeatureService from '~/services/feature.service';
import ClientPortalTooltip from '../salesPackages/clientPortal/ClientPortalTooltip';
import UserMetrics from '../userMetrics/UserMetrics';
import DeliveryNoteDataQuality from '../deliveries/deliveryNoteDataQuality/DeliveryNoteDataQuality';

import DataExchangePage from '~/components/dataExchanges/DataExchangePage';
import ModuleInvoiceRestrictionTooltip from '../salesPackages/moduleInvoiceRestriction/ModuleInvoiceRestrictionTooltip';
import PackageBasicRestrictionTooltip from '../salesPackages/packageBasicRestriction/packageBasicRestrictionTooltip';

import DeliveriesService from '~/services/deliveries.service';

// Set hover color of the menu items to the right blue tone
const StyledList = styled(List)({
  flex: 1,
  padding: 0,
  '& .MuiListItemButton-root:hover': {
    backgroundColor: '#12306D',
  },
});

// Set hover color of the drawer toggle button to the right blue tone
const StyledButton = styled(Button)({
  ':hover': {
    backgroundColor: '#12306D',
  },
});

export const Drawer = (props) => {
  const useStyles = makeStyles((theme) => ({
    drawer: {
      width: props.drawerWidth,
      flexShrink: 0,
      transition: 'width 1s ease-in-out', // slide drawer in and out
      backgroundColor: '#0C1E44',
    },
    drawerPaper: {
      height: '100vh',
      width: props.drawerWidth,
      backgroundColor: '#0C1E44',
      color: '#ffffff',
      transition: 'width 1s ease-in-out', // slide drawer in and out
    },
    content: {
      flexGrow: 1,
      marginTop: '63px',
      height: `calc(100% - 63px)`,
      width: `calc(100% - ${props.drawerWidth}px)`,
    },
    listItemHighlighted: {
      backgroundColor: '#12306D !important',
    },
    listItemSelected: {
      backgroundColor: '#12306D !important',
      color: '#80A9FD !important',
    },
    item_listItemIcon: {
      minWidth: '45px !important',
    },
    subItem_listItemText: {
      fontSize: '14px !important',
    },
    subItem_listItemIcon: {
      minWidth: '35px !important',
      marginLeft: '15px',
    },
  }));

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userinfo = useSelector((state) => state.userinfo);
  const filters = useSelector((state) => state.filters);
  const dataExchanges = useSelector((state) => state.dataExchanges);
  const dashboardDateRange = useSelector(
    (state) => state.filters.dashboard_selectedDateRange,
  );
  // subscribe to companyAccount state so that update of clientPortal feature flag leads to rerender
  const companyAccount = useSelector((state) => state.companyAccount);
  const [openMenuItem, setOpenMenuItem] = useState('');
  const [drawerSliding, setDrawerSliding] = useState(false);

  const menuItems = [
    {
      name: 'Home',
      filledIcon: <HomeIcon className="icon-small" />,
      outlinedIcon: <HomeOutlinedIcon className="icon-small" />,
      subItems: [
        {
          name: 'Home',
          route: ROUTE.HOME.ROUTE,
          filledIcon: <HomeIcon className="icon-extra-small" />,
          outlinedIcon: <HomeOutlinedIcon className="icon-extra-small" />,
          selected:
            location.pathname === ROUTE.HOME.ROUTE ||
            location.pathname === '/home',
        },
      ],
    },
    {
      name: 'Statistiken',
      filledIcon: <PieChartIcon className="icon-small" />,
      outlinedIcon: <PieChartOutlinedIcon className="icon-small" />,
      subItems: [
        {
          name: TAB.DASHBOARD.CHARTS.NAME,
          route: ROUTE.DASHBOARD.ROUTE,
          filledIcon: <PieChartIcon className="icon-extra-small" />,
          outlinedIcon: <PieChartOutlinedIcon className="icon-extra-small" />,
          callback: () =>
            dispatch(setDashboard_tab(TAB.DASHBOARD.CHARTS.INDEX)),
          selected:
            location.pathname === ROUTE.DASHBOARD.ROUTE &&
            filters.dashboard_tab === TAB.DASHBOARD.CHARTS.INDEX,
        },
        {
          name: TAB.DASHBOARD.REPORT.NAME,
          route: ROUTE.DASHBOARD.ROUTE,
          filledIcon: <ReportIcon className="icon-extra-small" />,
          outlinedIcon: <ReportOutlinedIcon className="icon-extra-small" />,
          callback() {
            dispatch(setDashboard_tab(TAB.DASHBOARD.REPORT.INDEX));
            Log.productAnalyticsEvent(
              'Open dashboard reports tab',
              Log.FEATURE.MENU,
            );
          },
          selected:
            location.pathname === ROUTE.DASHBOARD.ROUTE &&
            filters.dashboard_tab === TAB.DASHBOARD.REPORT.INDEX,
          disabled: DeliveriesService.isArchiveMode(dashboardDateRange),
          disabledByClientPortal: FeatureService.clientPortal(),
          disabledByPackageBasicRestriction:
            FeatureService.packageBasicRestriction(),
        },
        {
          name: 'Benutzerstatistiken',
          route: ROUTE.USER_METRICS.ROUTE,
          filledIcon: <PersonSearchIcon className="icon-extra-small" />,
          outlinedIcon: (
            <PersonSearchOutlinedIcon className="icon-extra-small" />
          ),
          selected: location.pathname === ROUTE.USER_METRICS.ROUTE,
          disabledByClientPortal: FeatureService.clientPortal(),
          disabledByPackageBasicRestriction:
            FeatureService.packageBasicRestriction(),
        },
      ],
    },
    {
      name: 'Lieferungen',
      filledIcon: <DeliveryIcon className="icon-small" />,
      outlinedIcon: <DeliveryOutlinedIcon className="icon-small" />,
      subItems: [
        {
          name: TAB.DELIVERY.LIST.NAME,
          route: ROUTE.DELIVERIES.ROUTE,
          filledIcon: <DeliveryIcon className="icon-extra-small" />,
          outlinedIcon: <DeliveryOutlinedIcon className="icon-extra-small" />,
          callback: () => dispatch(setDelivery_tab(TAB.DELIVERY.LIST.INDEX)),
          selected:
            (location.pathname === ROUTE.DELIVERIES.ROUTE &&
              filters.delivery_tab !== TAB.DELIVERY.CHANGES.INDEX) ||
            RouteUtils.getMainRoute(location.pathname) ===
              ROUTE.DELIVERIES.ROUTE,
        },
        {
          name: TAB.DELIVERY.CHANGES.NAME,
          route: ROUTE.DELIVERIES.ROUTE,
          filledIcon: <EditIcon className="icon-extra-small" />,
          outlinedIcon: <EditOutlinedIcon className="icon-extra-small" />,
          callback() {
            dispatch(setDelivery_tab(TAB.DELIVERY.CHANGES.INDEX));
            Log.productAnalyticsEvent(
              'Open delivery changes tab',
              Log.FEATURE.MENU,
            );
          },
          selected:
            location.pathname === ROUTE.DELIVERIES.ROUTE &&
            filters.delivery_tab === TAB.DELIVERY.CHANGES.INDEX,
          disabledByClientPortal: FeatureService.clientPortal(),
        },
        {
          name: 'Lieferung anlegen',
          route: ROUTE.CREATE_DELIVERY_NOTE.ROUTE,
          filledIcon: <CreateDeliveryIcon className="icon-extra-small" />,
          outlinedIcon: (
            <CreateDeliveryOutlinedIcon className="icon-extra-small" />
          ),
          selected: location.pathname === ROUTE.CREATE_DELIVERY_NOTE.ROUTE,
        },
        {
          name: 'Leistungsnachweis anlegen',
          route: ROUTE.CREATE_SERVICE_NOTE.ROUTE,
          filledIcon: <CreateDeliveryIcon className="icon-extra-small" />,
          outlinedIcon: (
            <CreateDeliveryOutlinedIcon className="icon-extra-small" />
          ),
          selected: location.pathname === ROUTE.CREATE_SERVICE_NOTE.ROUTE,
        },
        {
          name: 'Betontagebuch',
          route: ROUTE.CONCRETE_DIARY.ROUTE,
          filledIcon: <FolderIcon className="icon-extra-small" />,
          outlinedIcon: <FolderOutlinedIcon className="icon-extra-small" />,
          selected: location.pathname === ROUTE.CONCRETE_DIARY.ROUTE,
          disabledByClientPortal: FeatureService.clientPortal(),
          disabledByPackageBasicRestriction:
            FeatureService.packageBasicRestriction(),
        },
      ],
    },
    {
      name: 'Rechnungen',
      filledIcon: <InvoiceIcon className="icon-small" />,
      outlinedIcon: <InvoiceOutlinedIcon className="icon-small" />,
      disabledByClientPortal: FeatureService.clientPortal(),
      disabledByModuleInvoiceRestriction:
        FeatureService.moduleInvoiceRestriction(),
      subItems: [
        {
          name: 'Eingangsrechnungen',
          route: ROUTE.INCOMING_INVOICES.ROUTE,
          filledIcon: <IncomingInvoiceIcon className="icon-extra-small" />,
          outlinedIcon: (
            <IncomingInvoiceOutlinedIcon className="icon-extra-small" />
          ),
          selected:
            location.pathname === ROUTE.INCOMING_INVOICES.ROUTE ||
            RouteUtils.getMainRoute(location.pathname) ===
              ROUTE.INCOMING_INVOICES.ROUTE,
          disabledByClientPortal: FeatureService.clientPortal(),
          disabledByModuleInvoiceRestriction:
            FeatureService.moduleInvoiceRestriction(),
        },
        {
          name: 'Ausgangsrechnungen',
          route: ROUTE.OUTGOING_INVOICES.ROUTE,
          filledIcon: <OutgoingInvoiceIcon className="icon-extra-small" />,
          outlinedIcon: (
            <OutgoingInvoiceOutlinedIcon className="icon-extra-small" />
          ),
          selected:
            location.pathname === ROUTE.OUTGOING_INVOICES.ROUTE ||
            RouteUtils.getMainRoute(location.pathname) ===
              ROUTE.OUTGOING_INVOICES.ROUTE,
          disabledByClientPortal: FeatureService.clientPortal(),
          disabledByModuleInvoiceRestriction:
            FeatureService.moduleInvoiceRestriction(),
        },
      ],
    },
    {
      name: 'Lieferanten',
      filledIcon: <SupplierIcon className="icon-small" />,
      outlinedIcon: <SupplierIcon className="icon-small" />,
      error: dataExchanges.currentIssues,
      beta: FeatureService.supplierKanban(),
      subItems: [
        {
          name: 'Lieferanten',
          route: ROUTE.DATA_EXCHANGE.ROUTE,
          filledIcon: <SupplierIcon className="icon-extra-small" />,
          outlinedIcon: <SupplierIcon className="icon-extra-small" />,
          selected: location.pathname === ROUTE.DATA_EXCHANGE.ROUTE,
        },
      ],
    },

    {
      name: 'Einstellungen',
      filledIcon: <SettingsIcon className="icon-small" />,
      outlinedIcon: <SettingsOutlinedIcon className="icon-small" />,
      subItems: [
        {
          name: 'Einstellungen',
          route: ROUTE.SETTINGS_MASTERDATA.ROUTE,
          filledIcon: <SettingsIcon className="icon-extra-small" />,
          outlinedIcon: <SettingsOutlinedIcon className="icon-extra-small" />,
          selected:
            location.pathname === ROUTE.SETTINGS_MASTERDATA.ROUTE ||
            RouteUtils.getMainRoute(location.pathname) === ROUTE.SETTINGS.ROUTE,
        },
      ],
    },
  ];

  const onMenuItemClick = (name, route, callback) => {
    Log.productAnalyticsEvent('Open ' + name, Log.FEATURE.MENU);

    setOpenMenuItem(name);

    onMenuSubItemClick(route, callback);
  };

  const onMenuSubItemClick = (route, callback) => {
    if (!UserService.userIsAuthorizedForPage(userinfo.userPermissions, route)) {
      switch (userinfo.userPermissionsLoading) {
        case LOADING_STATE.LOADING: {
          ToastService.info(['Berechtigungen werden geladen...']);
          break;
        }

        case LOADING_STATE.SUCCEEDED: {
          ToastService.warning([
            'Kein Zugriff: Tritt mit deinem Admin in Kontakt, um die fehlende Berechtigung anzufordern.',
          ]);
          Log.productAnalyticsEvent(
            'Missing access for this page',
            Log.FEATURE.MENU,
            Log.TYPE.ERROR,
          );
          break;
        }

        case LOADING_STATE.FAILED: {
          ToastService.error([
            'Berechtigungen konnten nicht geladen werden.',
            ToastService.MESSAGE.CONTACT_SUPPORT,
          ]);
          Log.productAnalyticsEvent(
            'Failed to load user permissions',
            Log.FEATURE.MENU,
            Log.TYPE.ERROR,
          );
          break;
        }
      }

      return;
    }

    history.push(route);

    if (callback) {
      callback();
    }
  };

  const onContextMenu = (event, name, route) => {
    event.preventDefault();

    Log.productAnalyticsEvent(
      'Open ' + name + ' via context menu',
      Log.FEATURE.MENU,
    );

    BrowserUtils.openMenuItemInNewTab(name, route);
  };

  useEffect(() => {
    if (!window.menu_item_name) {
      return;
    }

    // call the callback function of the passed menu item so that the state change is also passed to the new tab
    for (const item of menuItems) {
      for (const subItem of item.subItems) {
        if (subItem.name === window.menu_item_name && subItem.callback) {
          subItem.callback();
        }
      }
    }

    // reset the passed menu item name so that the callback function isn't called twice accidentally
    window.menu_item_name = null;
  }, [window.menu_item_name]);

  // If the user isn't using the drawer to navigate (e.g. via back button or directly opening page via link), the selected menu item might differ to the openMenuItem.
  // This leads to the wrong menu item to be unfolded. Hence, checking whether openMenuItem is correctly set, ensures that the correct menu item is unfolded.
  // This check is only done when the location has changed because otherwise the menu items couldn't be unfolded manually by button click anymore.
  useEffect(() => {
    const selectedMenuItem = menuItems.find(({ subItems }) =>
      subItems.find(({ selected }) => selected),
    );

    if (selectedMenuItem && openMenuItem !== selectedMenuItem.name) {
      setOpenMenuItem(selectedMenuItem.name);
    }
  }, [location]);

  const onExpandClick = (event, name) => {
    if (event) {
      event.stopPropagation();
    }

    if (openMenuItem === name) {
      setOpenMenuItem('');
      Log.productAnalyticsEvent('Hide menu item', Log.FEATURE.MENU);
    } else {
      setOpenMenuItem(name);
      Log.productAnalyticsEvent('Expand menu item', Log.FEATURE.MENU);
    }
  };

  // Only display menu items to which pages the user has access to
  const defaultDisplayedMenuItems = new Set([
    ROUTE.DASHBOARD.ROUTE,
    ROUTE.DELIVERIES.ROUTE,
  ]);
  const clientPortalDisplayedMenuItems = new Set([
    ROUTE.DASHBOARD.ROUTE,
    ROUTE.DELIVERIES.ROUTE,
    ROUTE.CONCRETE_DIARY.ROUTE,
    ROUTE.INCOMING_INVOICES.ROUTE,
    ROUTE.OUTGOING_INVOICES.ROUTE,
  ]);
  const moduleInvoiceRestrictionDisplayedMenuItems = new Set([
    ROUTE.INCOMING_INVOICES.ROUTE,
    ROUTE.OUTGOING_INVOICES.ROUTE,
  ]);
  const packageBasicRestrictionDisplayedMenuItems = new Set([
    ROUTE.DASHBOARD.ROUTE,
    ROUTE.DELIVERIES.ROUTE,
    ROUTE.CONCRETE_DIARY.ROUTE,
  ]);
  const displayedMenuItems = menuItems.map((item) => {
    return {
      ...item,
      subItems: item.subItems.filter(
        (subItem) =>
          defaultDisplayedMenuItems.has(subItem.route) ||
          (FeatureService.clientPortal() &&
            clientPortalDisplayedMenuItems.has(subItem.route)) ||
          (FeatureService.moduleInvoiceRestriction() &&
            moduleInvoiceRestrictionDisplayedMenuItems.has(subItem.route)) ||
          (FeatureService.packageBasicRestriction() &&
            packageBasicRestrictionDisplayedMenuItems.has(subItem.route)) ||
          UserService.userIsAuthorizedForPage(
            userinfo.userPermissions,
            subItem.route,
          ),
      ),
    };
  });

  const handleDrawerToggle = async () => {
    if (props.hideDrawer) {
      Log.productAnalyticsEvent('Open drawer', Log.FEATURE.MENU);
    } else {
      Log.productAnalyticsEvent('Close drawer', Log.FEATURE.MENU);
    }

    props.setHideDrawer(!props.hideDrawer);
    LocalStorageService.setLocalStorage(
      LocalStorageService.HIDE_DRAWER,
      !props.hideDrawer,
    );

    setDrawerSliding(true);
    await FunctionUtils.timer(1000); // 1000ms = 1s = transition duration of drawer ease in and out
    setDrawerSliding(false);
  };

  // The names of the menu items should not be displayed if the drawer is not completely open
  const showMenuItemName = !props.hideDrawer && !drawerSliding;

  const withTooltip = (component, title) => {
    if (showMenuItemName) {
      return component;
    }

    return <LightTooltip title={title}>{component}</LightTooltip>;
  };

  const withSalesTooltip = (
    component,
    disabledByClientPortal,
    disabledByModuleInvoiceRestriction,
    disabledByPackageBasicRestriction,
  ) => {
    if (disabledByPackageBasicRestriction) {
      return (
        <PackageBasicRestrictionTooltip className="w-full">
          {component}
        </PackageBasicRestrictionTooltip>
      );
    }

    if (disabledByModuleInvoiceRestriction) {
      return (
        <ModuleInvoiceRestrictionTooltip className="w-full">
          {component}
        </ModuleInvoiceRestrictionTooltip>
      );
    }

    if (disabledByClientPortal) {
      return (
        <ClientPortalTooltip className="w-full">
          {component}
        </ClientPortalTooltip>
      );
    }

    return component;
  };

  const drawer = (
    <div className="relative flex h-full w-full flex-col">
      <div className="flex items-center px-4 py-2">
        <img width="60%" src={VestigasLogo} />
      </div>
      <Divider />
      <StyledList>
        {displayedMenuItems.map((item) => {
          if (item.subItems.length === 0) {
            return null;
          }

          const itemSelected =
            item.subItems.length === 1 && item.subItems[0].selected;
          const itemHighlighted =
            item.subItems.length > 1 &&
            item.subItems.find((subItem) => subItem.selected);

          return (
            <div key={item.name}>
              {withSalesTooltip(
                <ListItemButton
                  key={item.name}
                  onMouseDown={() =>
                    onMenuItemClick(
                      item.name,
                      item.subItems[0].route,
                      item.subItems[0].callback,
                    )
                  }
                  onContextMenu={(event) =>
                    onContextMenu(
                      event,
                      item.subItems[0].name,
                      item.subItems[0].route,
                    )
                  }
                  classes={{
                    selected: itemSelected
                      ? classes.listItemSelected
                      : classes.listItemHighlighted,
                  }}
                  selected={Boolean(itemSelected || itemHighlighted)}
                  disabled={
                    item.disabled ||
                    item.disabledByClientPortal ||
                    item.disabledByModuleInvoiceRestriction ||
                    item.disabledByPackageBasicRestriction
                  }
                >
                  <div className="flex-s-c w-full">
                    <div
                      className={
                        'w-5px h-35px ml--16px mr-15px rounded-right-5px ' +
                        (itemSelected ? 'bg-primaryLight300' : '')
                      }
                    />
                    {withTooltip(
                      <ListItemIcon
                        classes={{ root: classes.item_listItemIcon }}
                        style={{
                          color: itemSelected ? '#80A9FD' : '#FFFFFF',
                          alignItems: 'center',
                        }}
                      >
                        {itemSelected || itemHighlighted
                          ? item.filledIcon
                          : item.outlinedIcon}
                      </ListItemIcon>,
                      item.name,
                    )}
                    <ListItemText primary={showMenuItemName ? item.name : ''} />
                    {item.beta ? (
                      <div className="h-20px w-40px rounded-10px bg-infoBase main-text text-12px flex-c-c">
                        beta
                      </div>
                    ) : null}
                    {item.error ? (
                      <InfoIcon className="text-error700 icon-15px" />
                    ) : null}
                  </div>
                  {item.subItems.length > 1 ? (
                    openMenuItem === item.name ? (
                      <ArrowDropUpIcon
                        onMouseDown={(e) => onExpandClick(e, item.name)}
                      />
                    ) : (
                      <ArrowDropDownIcon
                        onMouseDown={(e) => onExpandClick(e, item.name)}
                      />
                    )
                  ) : null}
                </ListItemButton>,
                item.disabledByClientPortal,
                item.disabledByModuleInvoiceRestriction,
                item.disabledByPackageBasicRestriction,
              )}
              {item.subItems.length > 1 ? (
                <Collapse
                  in={openMenuItem === item.name}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem) => (
                      <div className="h-45px flex-s-c" key={subItem.name}>
                        {withSalesTooltip(
                          <ListItemButton
                            key={subItem.name}
                            sx={{ pl: 4, height: '100%' }}
                            onMouseDown={() =>
                              onMenuSubItemClick(
                                subItem.route,
                                subItem.callback,
                              )
                            }
                            onContextMenu={(e) =>
                              onContextMenu(e, subItem.name, subItem.route)
                            }
                            classes={{
                              selected: subItem.selected
                                ? classes.listItemSelected
                                : classes.listItemHighlighted,
                            }}
                            selected={Boolean(itemSelected || itemHighlighted)}
                            disabled={
                              subItem.disabled ||
                              subItem.disabledByClientPortal ||
                              subItem.disabledByModuleInvoiceRestriction ||
                              subItem.disabledByPackageBasicRestriction
                            }
                          >
                            <div className="flex-s-c w-full">
                              <div
                                className={
                                  'w-5px h-25px ml--32px mr-25px rounded-right-5px ' +
                                  (subItem.selected ? 'bg-primaryLight300' : '')
                                }
                              />
                              {withTooltip(
                                <ListItemIcon
                                  classes={{
                                    root: classes.subItem_listItemIcon,
                                  }}
                                  style={{
                                    color: subItem.selected
                                      ? '#80A9FD'
                                      : '#FFFFFF',
                                    alignItems: 'center',
                                  }}
                                >
                                  {subItem.selected
                                    ? subItem.filledIcon
                                    : subItem.outlinedIcon}
                                </ListItemIcon>,
                                subItem.name,
                              )}
                              <ListItemText
                                primary={showMenuItemName ? subItem.name : ''}
                                classes={{
                                  primary: classes.subItem_listItemText,
                                }}
                              />
                            </div>
                          </ListItemButton>,
                          subItem.disabledByClientPortal,
                          subItem.disabledByModuleInvoiceRestriction,
                          subItem.disabledByPackageBasicRestriction,
                        )}
                      </div>
                    ))}
                  </List>
                </Collapse>
              ) : null}
            </div>
          );
        })}
      </StyledList>
      {withTooltip(
        <div className="absolute bottom-0 h-12 w-full">
          <StyledButton
            variant="text"
            color="inherit"
            size="large"
            startIcon={
              showMenuItemName ? (
                <KeyboardArrowLeftIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )
            }
            onMouseDown={handleDrawerToggle}
            className="h-full w-full"
          >
            {showMenuItemName ? 'Einklappen' : null}
          </StyledButton>
        </div>,
        'Ausklappen',
      )}
    </div>
  );

  return (
    <>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smDown implementation="css">
          <MuiDrawer
            classes={{ paper: classes.drawerPaper }}
            variant="permanent"
            open
          >
            {drawer}
          </MuiDrawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className="main-background h-full overflow-auto">
          <div className="h-full">
            {UserService.userIsAuthorizedForPage(
              userinfo.userPermissions,
              location.pathname,
            ) ? (
              <Switch>
                <SentryRoute exact path={ROUTE.HOME.ROUTE} component={Home} />
                <SentryRoute exact path="/home" component={Home} />
                <SentryRoute
                  exact
                  path={ROUTE.DASHBOARD.ROUTE}
                  component={DashboardOverview}
                />
                <SentryRoute
                  exact
                  path={ROUTE.USER_METRICS.ROUTE}
                  component={UserMetrics}
                />
                <SentryRoute
                  exact
                  path={ROUTE.DELIVERIES.ROUTE}
                  component={DeliveryOverview}
                />
                <SentryRoute
                  path={ROUTE.DELIVERY_NOTE.ROUTE + '/:id'}
                  component={DeliveryNote}
                />
                <SentryRoute
                  exact
                  path={ROUTE.CREATE_DELIVERY_NOTE.ROUTE}
                  component={DeliveryNoteForm}
                />
                <SentryRoute
                  exact
                  path={ROUTE.CREATE_SERVICE_NOTE.ROUTE}
                  component={ServiceNoteForm}
                />
                <SentryRoute
                  exact
                  path={ROUTE.CONCRETE_DIARY.ROUTE}
                  component={ConcreteDiary}
                />
                <SentryRoute
                  path={ROUTE.DELIVERY_NOTE_DATA_QUALITY.ROUTE + '/:id'}
                  component={DeliveryNoteDataQuality}
                />
                <SentryRoute
                  exact
                  path={ROUTE.OUTGOING_INVOICES.ROUTE}
                  component={InvoiceOverview}
                />
                <SentryRoute
                  exact
                  path={ROUTE.INCOMING_INVOICES.ROUTE}
                  component={InvoiceOverview}
                />
                <SentryRoute
                  path={ROUTE.OUTGOING_INVOICE.ROUTE + '/:id'}
                  component={Invoice}
                />
                <SentryRoute
                  path={ROUTE.INCOMING_INVOICE.ROUTE + '/:id'}
                  component={Invoice}
                />
                <SentryRoute
                  path={ROUTE.INVOICE.ROUTE + '/:id'}
                  component={Invoice}
                />
                <SentryRoute path={ROUTE.SETTINGS.ROUTE} component={Settings} />
                <SentryRoute
                  path={ROUTE.DATA_EXCHANGE.ROUTE}
                  component={DataExchangePage}
                />
              </Switch>
            ) : (
              <UnauthorizedPage />
            )}
          </div>
        </div>
      </main>
    </>
  );
};
