import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthProvider } from 'oidc-react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import store from '~/redux/store';

import { ROUTE } from './constants/Route';

import AuthService from './services/auth.service';
import ThirdPartyService from '~/services/thirdParty.service';

import { isLocalhost } from '~/utils/env';

import { AppInitializer } from './components/menu/AppInitializer';
import IdsRedirectPage from './components/ids/IdsRedirectPage';

import { theme } from '~/ui/styles';
import '~/ui/styles/legacy.css';
import '~/ui/styles/index.css';

if (!isLocalhost) {
  ThirdPartyService.initSentry();
}

ThirdPartyService.initMuiPro();

const queryClient = new QueryClient();

export const App = () => {
  if (window.location.pathname === ROUTE.IDS_REDIRECT.ROUTE) {
    return <IdsRedirectPage />;
  }

  return (
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <AuthProvider {...(AuthService ? AuthService.oidcConfig : {})}>
                <AppInitializer />
              </AuthProvider>
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </ReduxProvider>
  );
};
