import React, { useMemo } from 'react';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import DatagridUtils from '~/utils/datagridUtils';
import ExportService from '~/services/export.service';
import Log from '~/utils/Log';
import ToastService from '~/services/toast.service';

import { GridToolbarCustomComponent } from './GridToolbarCustomComponent';

export const GridToolbar = ({
  excelColumns,
  excelData,
  multiplePdfDownload,
  noColumnsButton,
  noExportButton,
  noFilterButton,
  onExcelDlnExport,
  onExcelInvoiceExport,
  onMapDirectDeliveryNote,
  onMultiPermissionGrantEdit,
  onOpenReport,
  onPdfExport,
  onRequestDeliveryNoteSignature,
  onRowHeightChange,
  onShareDeliveryNote,
  productAnalyticsFeature,
  rowHeight,
}) => {
  const handleRowHeightChange = (rowHeight) => {
    onRowHeightChange(rowHeight);
  };

  const rowHeightMenuItem = (name, height) => {
    return {
      name,
      onClick: () => handleRowHeightChange(height),
      selected: rowHeight === height,
    };
  };

  const handleExcelExport = (withProductAnalyticsEvent) => {
    if (withProductAnalyticsEvent) {
      Log.productAnalyticsEvent(
        'Download Excel',
        productAnalyticsFeature ?? Log.FEATURE.EXCEL_DOWNLOAD,
      );
    }

    if (excelData.length === 0) {
      ToastService.info([
        'Bitte wähle für den Excel-Download mindestens einen Eintrag aus der Tabelle aus.',
      ]);
      Log.productAnalyticsEvent(
        'No row selected in Excel-Download',
        productAnalyticsFeature ?? Log.FEATURE.EXCEL_DOWNLOAD,
        Log.TYPE.FAILED_VALIDATION,
      );
      return;
    }

    const rows = [excelColumns.map(({ headerName }) => headerName)];

    rows.push(
      ...excelData.map((data) =>
        excelColumns.flatMap(({ field }) => data[field]),
      ),
    );

    ExportService.exportExcel(rows);
  };

  const handleExcelDlnExport = (downloadOption) => {
    if (ExportService.DOWNLOAD_OPTION.ZIP) {
      Log.productAnalyticsEvent(
        'Download Excel with multiple PDFs as .zip file',
        productAnalyticsFeature ?? Log.FEATURE.EXCEL_DOWNLOAD,
      );
    }

    if (ExportService.DOWNLOAD_OPTION.MERGE) {
      Log.productAnalyticsEvent(
        'Download Excel with multiple PDFs as one merged document',
        productAnalyticsFeature ?? Log.FEATURE.EXCEL_DOWNLOAD,
      );
    }

    handleExcelExport();

    onExcelDlnExport(downloadOption);
  };

  const handleExcelInvoiceExport = () => {
    Log.productAnalyticsEvent(
      'Download invoice as Excel',
      Log.FEATURE.EXCEL_DOWNLOAD,
    );
    onExcelInvoiceExport();
  };

  const customComponents = useMemo(() => {
    const components = [];

    if (rowHeight) {
      components.push({
        title: 'Zeilenhöhe',
        icon: 'rowHeight',
        menuItems: [
          rowHeightMenuItem('Sehr Dünn', DatagridUtils.ROW_HEIGHT.VERY_THIN),
          rowHeightMenuItem('Dünn', DatagridUtils.ROW_HEIGHT.THIN),
          rowHeightMenuItem('Standard', DatagridUtils.ROW_HEIGHT.STANDARD),
          rowHeightMenuItem('Breit', DatagridUtils.ROW_HEIGHT.WIDE),
        ],
      });
    }

    if (onPdfExport) {
      const pdfExportMenuItems = multiplePdfDownload
        ? [
            {
              name: 'Download als PDF (.zip-Datei)',
              onClick: () => onPdfExport(ExportService.DOWNLOAD_OPTION.ZIP),
            },
            {
              name: 'Download als PDF (ein Dokument)',
              onClick: () => onPdfExport(ExportService.DOWNLOAD_OPTION.MERGE),
            },
          ]
        : [
            {
              name: 'Download als PDF',
              onClick: () => onPdfExport(),
            },
          ];

      components.push({
        title: 'PDF Download',
        icon: 'pdf',
        menuItems: pdfExportMenuItems,
      });
    }

    if (excelData && excelColumns) {
      const excelExportMenuItems = [
        {
          name: 'Download als Excel',
          onClick: () => handleExcelExport(true),
        },
      ];

      if (onExcelDlnExport) {
        if (multiplePdfDownload) {
          excelExportMenuItems.push(
            {
              name: 'Download als Excel mit PDF-Lieferungen (.zip-Datei)',
              onClick: () =>
                handleExcelDlnExport(ExportService.DOWNLOAD_OPTION.ZIP),
            },
            {
              name: 'Download als Excel mit PDF-Lieferungen (ein Dokument)',
              onClick: () =>
                handleExcelDlnExport(ExportService.DOWNLOAD_OPTION.MERGE),
            },
          );
        } else {
          excelExportMenuItems.push({
            name: 'Download als Excel mit PDF-Lieferungen',
            onClick: () => handleExcelDlnExport(),
          });
        }
      }

      if (onExcelInvoiceExport) {
        excelExportMenuItems.push({
          name: 'Download einzelne Rechnungen als Excel',
          onClick: () => handleExcelInvoiceExport(),
        });
      }

      components.push({
        title: 'Excel Download',
        icon: 'excel',
        menuItems: excelExportMenuItems,
      });
    }

    if (onMultiPermissionGrantEdit) {
      components.push({
        title: 'Berechtigungen vergeben',
        icon: 'permissionGrant',
        menuItems: [
          {
            name: 'Berechtigungen vergeben',
            onClick: onMultiPermissionGrantEdit,
          },
        ],
      });
    }

    if (onRequestDeliveryNoteSignature) {
      components.push({
        title: 'Signaturen anfordern',
        icon: 'requestSignature',
        menuItems: [
          {
            name: 'Signaturen anfordern',
            onClick: onRequestDeliveryNoteSignature,
          },
        ],
      });
    }

    if (onShareDeliveryNote) {
      components.push({
        title: 'Lieferungen teilen',
        icon: 'shareDeliveryNote',
        menuItems: [
          {
            name: 'Lieferungen teilen',
            onClick: onShareDeliveryNote,
          },
        ],
      });
    }

    if (onMapDirectDeliveryNote) {
      components.push({
        title: 'Standort zuweisen',
        icon: 'mapDirectDeliveryNote',
        menuItems: [
          {
            name: 'Standort zuweisen',
            onClick: onMapDirectDeliveryNote,
          },
        ],
      });
    }

    if (onOpenReport) {
      components.push({
        title: 'Statistiken',
        icon: 'report',
        menuItems: [
          {
            name: 'Statistiken',
            onClick: onOpenReport,
          },
        ],
      });
    }

    return components;
  }, [
    rowHeight,
    onPdfExport,
    excelData,
    excelColumns,
    onExcelDlnExport,
    onExcelInvoiceExport,
    onMultiPermissionGrantEdit,
    onRequestDeliveryNoteSignature,
    onShareDeliveryNote,
    onMapDirectDeliveryNote,
    onOpenReport,
    multiplePdfDownload,
  ]);

  return (
    <GridToolbarContainer className="flex gap-2">
      {noColumnsButton ? null : <GridToolbarColumnsButton />}
      {noFilterButton ? null : <GridToolbarFilterButton />}
      {noExportButton ? null : (
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      )}
      {customComponents.map(({ icon, menuItems, title }) => (
        <GridToolbarCustomComponent
          key={title}
          title={title}
          icon={icon}
          menuItems={menuItems}
        />
      ))}
    </GridToolbarContainer>
  );
};
