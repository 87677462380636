import { useDispatch, useSelector } from 'react-redux';

import UserService from '~/services/user.service';
import DeliveriesService from '~/services/deliveries.service';

import { LOADING_STATE } from '~/constants/LoadingState';

import {
  saveDeliveryNotes,
  saveDeliveryChanges,
  setDeliveryNotesLoading,
  replaceBrowsableDeliveryNotes,
} from '~/redux/deliveryNotesSlice';
import { saveUserCompany } from '~/redux/userinfoSlice';

import { promiseHandler } from '~/utils/promiseHandler';
import Log from '~/utils/Log';

/**
 * Custom hook for loading delivery notes.
 *
 * This hook provides functionality to load delivery notes either from the window.redux_state
 * (if available and successfully loaded) or by fetching from the backend.
 * It also handles loading the user's company information if not already loaded.
 *
 * @returns {Object} An object containing the loadDeliveryNotes function.
 */
export const useLoadDeliveryNotes = () => {
  const dispatch = useDispatch();

  const { selectedCostCenters, selectedSites } = useSelector(
    (state) => state.filters,
  );

  /**
   * Loads delivery notes and associated data.
   *
   * @param {boolean} companyLoaded - Indicates whether the company data has already been loaded.
   * @returns {Promise<void>}
   */
  async function loadDeliveryNotes(companyLoaded) {
    if (
      /**
       * Opening an item in a new browser tab using BrowserUtils.openMenuItemInNewTab or BrowserUtils.openNewTab
       * passes the whole Redux state to the new tab as window.redux_state.
       * If that is the case, we don't need to load the data again from the backend,
       * but push the data from the window object into Redux state.
       */
      window.redux_state &&
      window.redux_state.deliveryNotes.deliveryNotesLoading ===
        LOADING_STATE.SUCCEEDED
    ) {
      dispatch(
        saveDeliveryNotes(window.redux_state.deliveryNotes.deliveryNotes),
      );
      dispatch(
        saveDeliveryChanges(window.redux_state.deliveryNotes.deliveryChanges),
      );
      dispatch(
        replaceBrowsableDeliveryNotes(
          window.redux_state.deliveryNotes.browsableDeliveryNotes,
        ),
      );

      Log.info('Delivery notes state passed via window.redux_state.');

      return;
    }

    if (!companyLoaded) {
      // Load data of current user's company from the backend.
      const [company, error] = await promiseHandler(UserService.getCompany());

      if (error) {
        Log.error('Failed to load user company.', error);
        Log.productAnalyticsEvent(
          'Failed to load user company',
          Log.FEATURE.OTHER_FEATURE,
          Log.TYPE.ERROR,
        );
      }

      if (company) {
        dispatch(saveUserCompany(company));
      }
    }

    dispatch(setDeliveryNotesLoading(LOADING_STATE.LOADING));

    // Load data of delivery notes from the backend.
    DeliveriesService.initStoredDlns(
      true,
      selectedSites,
      selectedCostCenters,
    ).catch((error) => {
      dispatch(setDeliveryNotesLoading(LOADING_STATE.FAILED));
    });
  }

  return {
    loadDeliveryNotes,
  };
};
