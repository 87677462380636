import clsx from 'clsx';
import {
  CheckCircle as CheckCircleIcon,
  Help as HelpIcon,
  PersonOutline as PersonOutlineIcon,
} from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/material';

import { FilterPinIcon } from '~/assets/icons';

import DataExchangeCompany from '~/models/dataExchanges/DataExchangeCompany';

import { truncate } from '~/utils/string';

import { Spinner } from '~/components/Spinner';

import { SupplierStatusFlag } from './SupplierStatusFlag';
import { CHANNEL_MAP } from './constants';

import { useSupplierCard } from './useSupplierCard';

export const SupplierCard = ({ onClick, supplier }) => {
  const {
    classes,
    contacts,
    contactLink,
    isActive,
    lastContact,
    logo,
    requestMoreInfo,
    responsiblePerson,
    shouldDisplayInvoicesStatus,
  } = useSupplierCard({ supplier });

  const dataExchange = supplier?.dataExchange;
  const name = supplier?.name;
  const statusDeliveryNote = supplier?.statusDeliveryNote;
  const statusInvoice = supplier?.statusInvoice;

  return (
    <div className={classes.cardContainer} onClick={onClick}>
      <div className="logo-container flex-s-s">
        {logo.isLoading && <Spinner />}
        {logo.isSuccess && logo.data && <img src={logo.data} alt={name} />}
      </div>
      <SupplierStatusFlag
        overallStatus={statusDeliveryNote}
        status={contactLink?.status}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="title"
        marginBottom={2}
      >
        <Typography>{name}</Typography>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            requestMoreInfo();
          }}
          className="email-icon flex-c-c"
          size="large"
        >
          <HelpIcon />
        </IconButton>
      </Box>
      <Divider />
      <div className="supplier-details">
        <div className="traits gap-8px">
          <div
            className={clsx('option', {
              active: isActive(statusDeliveryNote),
            })}
          >
            <Typography className="label">Lieferungen</Typography>
            <div className="value">
              {isActive(statusDeliveryNote) ? <CheckCircleIcon /> : null}
              <Typography>
                {DataExchangeCompany.getStatusAbbreviatedLabel(
                  statusDeliveryNote,
                )}
              </Typography>
            </div>
          </div>
          {shouldDisplayInvoicesStatus && (
            <div
              className={clsx('option', {
                active: isActive(statusInvoice),
              })}
            >
              <Typography className="label">Rechnungen</Typography>
              <div className="value">
                {isActive(statusInvoice) ? <CheckCircleIcon /> : null}
                {DataExchangeCompany.getStatusAbbreviatedLabel(statusInvoice)}
              </div>
            </div>
          )}
        </div>
        <div className="contact gap-8px">
          <div className="option">
            <PersonOutlineIcon fontSize="small" />
            {dataExchange?.responsiblePerson ? (
              <Typography className="text-overflow">
                {truncate(responsiblePerson, 20)}
              </Typography>
            ) : (
              <>&mdash;</>
            )}
          </div>
          <div className="option">
            <FilterPinIcon />
            <Typography>{contacts} Kundennummern</Typography>
          </div>
          <div className="option">
            {dataExchange?.lastContactAttemptTimestamp ? (
              <>
                {CHANNEL_MAP[dataExchange?.lastContactAttempt?.channel]}
                {lastContact}
              </>
            ) : (
              <>
                {CHANNEL_MAP.phone}
                &mdash;
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
